/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CheckoutGuestForm {
    background: $white;
    margin: 0 auto;
    max-width: 732px;
    margin: 0 auto;
    padding: 24px 20px;
    width: 100%;

    @include desktop {
        padding: 24px 40px 40px;
    }

    &-cols {
        @include desktop {
            display: grid;
            grid-template-columns: calc(50% - 32px) calc(50% - 32px);
            grid-column-gap: 64px;
        }
    }

    &-col {
        @include desktop {
            flex-basis: 50%;
        }

        &:first-child {
            &:after {
                @include desktop {
                    content: "";
                    height: 100%;
                    border-left: 1px solid $grey;
                    position: absolute;
                    right: -32px;
                    top: 0;
                }
            }

            .Field {
                &:first-child {
                    .Checkout & {
                        @include desktop {
                            margin-top: 6px;
                        }
                    }
                }
            }
        }

        &:not(.is-active) {
            @include mobile {
                height: 0;
                width: 0;
                overflow: hidden;
            }
        }
    }

    &-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        margin-bottom: 28px;

        @include desktop {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 32px;
        }
    }

    form {
        text-align: right;

        img.password_eye {
            margin-bottom: -32px;
        }
    }

    .Field {
        margin-top: 8px;
        text-align: left;

        &_type {
            &_checkbox {
                label {
                    align-items: flex-end;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;

                    .input-control {
                        margin-inline-start: 0;
                        margin-inline-end: 12px;
                    }
                }
            }
        }

        &.password {
            .Checkout & {
                margin-top: 22px;
            }
        }
    }

    .Checkout_social_login {
        border-top: 1px solid $grey;
        margin-top: 16px;
        margin-bottom: 0;
        padding-top: 20px;

        label {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            text-align: center;
            margin-bottom: 8px;
            padding-bottom: 0;
            width: 100%;
        }

        a {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            width: calc(50% - 8px);
            display: flex;
            gap: 12px;
            align-items: center;
            justify-content: center;
            height: 46px;
            border: 1px solid $grey;

            &:hover {
                text-decoration: none;
            }
        }

        .Field {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;
            margin: 0;
        }
    }

    &-Heading {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        margin: 0 0 12px;
    }

    &_email {
        height: 0;
        width: 0;
        overflow: hidden;
    }

    &_text {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
    }

    .MyAccountOverlay {
        &-Additional {
            padding-top: 0;
        }

        &-SignInButton {
            width: 100%;
        }
    }

    &__Options {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 40px;

        @include desktop {
            display: none !important;
        }
    }

    &__Option {
        border: 1px solid $grey;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        padding: 12px 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;

        &:before {
            content: "";
            box-sizing: border-box;
            width: 18px;
            height: 18px;
            border: 5px solid $white;
            border-radius: 50%;
            box-shadow: 0 0 0 1px $grey;
            background: $white;
        }

        &.is-active {
            &:before {
                box-shadow: 0 0 0 1px $black;
                background: $black;
            }
        }
    }
}
