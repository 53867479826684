/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkout-max-width: 1100px;
}

.Checkout {
    background: $icegrey;

    &_Success {
        background: none;
    }

    &_back_to_cart {
        max-width: 732px;
        margin: 32px auto 0;

        .Button_isHollow {
            width: auto;
            font-size: 12px;
            letter-spacing: 0.04em;
            cursor: pointer;

            &:visited {
                color: unset !important;
                border-color: var(--hollow-button-hover-border) !important;
            }

            &:before {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                border-width: 1px;
                border-style: solid;
                border-color: transparent $black $black transparent;
                transform: rotate(135deg);
                margin-right: 16px;
            }

            &:hover {
                background-color: $black !important;
                color: $white !important;
                text-decoration: none;

                &:before {
                    border-color: transparent $white $white transparent;
                }
            }
        }
    }

    &-StickyButtonWrapper {
        @include mobile {
            box-shadow: 0px -2px 4px $greyF0F0F0;
            position: fixed;
            inset-inline-start: 0;
            width: 100%;
            z-index: 85;
            inset-block-end: 0;
            background: $white;
            padding: 6px 16px 6px;
            display: flex;
            align-items: flex-end;
            gap: 10px;
        }

        @include desktop {
            display: flex;
            justify-content: space-between;
            margin-top: 32px;
        }

        .Button {
            @include mobile {
                width: 100%;
            }

            &:first-child {
                background: none;
                border-color: $black;

                @include mobile {
                    border-color: $grey;
                    font-size: 0;
                    width: 48px;
                    height: 48px;
                }

                @include desktop {
                    color: $black;
                }

                &:before {
                    content: "";
                    box-sizing: border-box;
                    width: 8px;
                    height: 8px;
                    border-style: solid;
                    border-width: 1px;
                    border-color: $black transparent transparent $black;
                    transform: rotate(-45deg);
                    position: absolute;
                    left: 50%;
                    top: calc(50% - 4px);

                    @include desktop {
                        left: 25px;
                    }
                }

                &:hover {
                    background-color: $black;
                    color: $white;
                    text-decoration: none;

                    &:before {
                        border-color: transparent $white $white transparent;
                        transform: rotate(135deg);
                    }
                }
            }

            &.CheckoutBilling-Button {
                width: 100%;
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                letter-spacing: 0.04em;

                &.Button {
                    &_isDisable {
                        cursor: not-allowed;
                        opacity: .25;
                    }
                }

                @include desktop {
                    width: auto;
                }

                &:first-child {
                    @include desktop {
                        padding-left: 52px;
                    }

                    @include mobile {
                        font-size: 0;
                        width: 48px;
                    }
                }
            }
        }
    }

    &-Wrapper {
        margin: auto;
        padding: 0 16px;

        &.ContentWrapper {
            max-width: 1387px;
        }
    }

    &-Heading {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        margin: 0;

        @include desktop {
            font-size: 20px;
            line-height: 28px;
        }
    }

    &-ProgressSection {
        @include desktop {
            display: inline-block;
            vertical-align: top;
            padding-top: 16px;
            padding-bottom: 8px;
        }

        &Wrapper {
            padding: 0 16px;

            @include desktop {
                text-align: center;
            }

            .Checkout_Success & {
                padding-top: 21px;
                padding-bottom: 40px;

                @include desktop {
                    padding-bottom: 8px;
                    padding-top: 40px;
                }
            }
        }
    }

    &-Header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 80px;

        @include desktop {
            justify-content: center;
            gap: 32px;
        }

        .Checkout_Success & {
            justify-content: center;
        }

        &:before {
            content: "";
            width: 100%;
            border-bottom: 1px solid $black;
            position: absolute;
            left: 0;
            right: 0;
            top: 40px;

            .Checkout_Success & {
                display: none;
            }
        }

        &Step {
            background: $icegrey;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            padding: 24px;

            @include mobile {
                flex-basis: 50%;
            }

            @include desktop {
                flex-direction: row;
            }

            @media (max-width: 350px) {
                padding: 24px 8px;
            }
        }

        &:not(.Checkout-Header_billing) {
            .Checkout-HeaderStep {
                &:last-child {
                    .Checkout {
                        &-Title,
                        &-SelectedStep {
                            opacity: 0.2;
                        }
                    }
                }
            }
        }

        &.Checkout-Header_billing {
            .Checkout-HeaderStep {
                &:first-child {
                    .Checkout {
                        &-Title,
                        &-SelectedStep {
                            opacity: 0.2;
                        }

                        &-SelectedStep {
                            font-size: 0;

                            &:after {
                                content: "";
                                width: 11px;
                                height: 4px;
                                border-width: 2px;
                                border-style: solid;
                                border-color: transparent transparent $white $white;
                                transform: rotate(-45deg);
                                margin-top: -5px;
                            }
                        }
                    }
                }
            }
        }

        &.is_virtual {
            justify-content: center;

            &:before {
                display: none;
            }

            .Checkout-HeaderStep {
                &:first-child:last-child {
                    min-height: 80px;

                    .Checkout {
                        &-Title,
                        &-SelectedStep {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &-Step {
        &Wrapper {
            padding-bottom: 60px;

            @include desktop {
                padding-bottom: 177px;
            }

            .Checkout_Success & {
                padding-bottom: 0;

                @include desktop {
                    border-bottom: 1px solid $grey;
                    margin-bottom: 37px;
                    padding-bottom: 60px;
                }
            }

            &_billing {
                @include desktop {
                    display: grid;
                    grid-template-columns: calc(64% - 16px) 36%;
                    grid-gap: 16px;
                }

                @include tablet {
                    grid-template-columns: calc(60% - 16px) 40%;
                }
            }
        }
    }

    &-StepBarTotal {
        background-color: var(--secondary-base-color);
        width: 100%;
        height: 4px;
        border-radius: 2px;
    }

    &-StepBarActive {
        position: relative;
        inset-block-start: -4px;
        background-color: var(--primary-base-color);
        width: 50%;
        height: 4px;
        border-radius: 2px;
        z-index: 2;

        &_isSecond {
            inset-inline-start: 50%;
        }
    }

    &-Title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        @include desktop {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.2px;
        }

        .Checkout_Success & {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.2px;

            @include desktop {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.003em;
            }
        }
    }

    &-SelectedStep {
        background: $grey727272;
        color: $white;
        border-radius: 40px;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
    }

    &-StepsBorder {
        font-size: 26px;
    }

    &-TotalSteps {
        font-size: 20px;
    }

    &-Coupon {
        @include desktop {
            margin-block-start: 30px;
        }
    }

    &-OrderTotal {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: bold;
        padding-block-end: 14px;

        @include desktop {
            display: none;
        }
    }

    &-ShippingButton {
        margin-inline-end: 10px;
        margin-block-end: 10px;
    }

    &-DeliverySelect {
        @include mobile {
            margin-block-start: 10px;
        }
    }

    .Checkout-ExpandableContentContent {
        margin-block-start: 0;
        padding: 0 16px;
    }

    .CheckoutOrderSummary-ButtonWrapper {

    }

    .Field {
        margin-top: 34px;

        &_type_checkbox {
            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.0008em;
            }
        }
    }

    &_type_document {
        margin-top: 19px;
        width: 100%;

        &_option {
            padding: 13px 24px 13px 48px;
            display: inline-block;
            margin: 15px 15px 0 0;

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.0008em;
            }

            .option {
                background: none;
                border: 1px solid $grey;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin: 0;
                cursor: pointer;

                &:before {
                    content: "";
                    border: 1px solid $grey;
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    margin-top: -9px;
                }

                &.active {
                    border-width: 1px;
                    border-color: $grey;

                    &.checked {
                        border-color: $black;

                        &:before {
                            border-color: $black;
                        }

                        &:after {
                            content: "";
                            background: $black;
                            border-radius: 50%;
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            left: 21px;
                            top: 50%;
                            margin-top: -3px;
                        }
                    }
                }
            }
        }
    }

    .type_document {
        .FieldForm-Fields {
            grid-column-gap: 14px;
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            max-width: 686px;

            @include desktop {
                grid-column-gap: 17px;
            }

            .Field {
                width: 100%;

                @include desktop {
                    width: calc(50% - 9px);
                }

                &.Field_type_checkbox {
                    width: 100%;
                }
            }
        }
    }

    &-RightSection {
        background: $white;
        box-shadow: 0px 4px 4px $greyF0F0F0;
        margin-block-start: 26px;

        @include desktop {
            margin-block-start: 0;
            overflow-y: auto;
        }

        &:empty {
            display: none;
        }

        &:not(.is-active) {
            @include desktop {
                max-height: 100% !important;
            }
        }

        &.is-active {
            @include desktop {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
            }
        }

        &.is-active-end {
            @include desktop {
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }
    }

    &_limitation {
        margin-top: 8px;

        .label {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            font-style: italic;
            color: red;

            @include desktop {
                text-align: end;
            }
        }
}

.CheckoutDocumentType {
    background: $white;
    box-shadow: 0px 4px 4px $greyF0F0F0;
    padding: 24px 20px 34px;
    margin-block-start: 16px;

    @include wide-desktop {
        padding: 32px 52px;
    }

    &:first-child {
        margin-top: 0;
    }
}
}