/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CheckoutPayments {
    background: $white;
    box-shadow: 0px 4px 4px $greyF0F0F0;
    padding: 24px 20px 34px;
    margin-block-start: 16px;

    @include wide-desktop {
        padding: 32px 52px;
    }

    .BankRedirect {
        img {
            width: auto;
            margin-top: 10px;
            max-width: 100%;
        }
    }

    .BankRedirect_group {
        height: 0;
        overflow: hidden;
        opacity: 0;

        &.active {
            height: auto;
            overflow: unset;
            opacity: 1;
        }
    }
}