/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --footer-totals-height: 0;
}

.CheckoutOrderSummary {
    $divider-border: 1px solid var(--input-border-color);

    .Checkout & {
        background: $white;
        box-shadow: 0px 4px 4px $greyF0F0F0;
        padding: 24px 16px 28px;
        margin-block-start: 40px;

        @include desktop {
            margin-block-start: 0;
        }

        @include wide-desktop {
            padding: 28px 44px 32px;
        }
    }

    &-Header {
        .CartPage-Summary & {
            display: none;
        }

        h2 {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            padding-bottom: 12px;
            margin: 0;

            @include desktop {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }

    &-ItemsInCart {
        font-size: 14px;
        inset-block-start: 2px;
        font-weight: 400;
        margin-block: 20px 16px;
    }

    &-SummaryItem {
        justify-content: space-between;
        display: flex;
        padding: 9px 0;
        margin-block-end: 0;
        padding-inline-start: 0;

        &:last-child {
            margin-block-end: 0;
        }

        &::before {
            content: none;
        }

        &_withAppendedContent {
            flex-wrap: wrap;
        }

        & strong,
        & p {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;

            .Checkout & {
                @include wide-desktop {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                }
            }
        }

        p {
            font-weight: 400;
        }

        &_isTotal {
            border-top: 1px solid $grey;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            padding: 24px 0;

            @include desktop {
                border-block-start: 1px solid var(--primary-divider-color);
            }

            & strong,
            & p {
                font-size: 18px;
                font-weight: 500;
                line-height: 26px;

                .Checkout & {
                    @include wide-desktop {
                        font-size: 20px;
                        line-height: 28px;
                    }
                }
            }

            .Checkout & {
                border-top: 0 none;
            }
        }

        .Checkout & {
            padding: 6px 0;
        }
    }

    &-OrderItems {
        @include mobile {
            background-color: var(--secondary-base-color);
        }

        h3 {
            text-align: start;
        }
    }

    &-CartItemList {
        list-style: none;
        margin: 0;
        padding: 0;

        .Checkout & {
            max-height: 308px;
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 8px;

            @include wide-desktop {
                padding-right: 24px;
            }

            > div {
                &:last-child {
                    .CartItem {
                        @include mobile {
                            border-bottom: 0 none;
                        }
                    }
                }
            }
        }
    }

    &-OrderTotals {
        .Checkout & {
            border-top: 1px solid $grey;
            padding-top: 10px;

            @include desktop {
                border-top: 0;
            }
        }
    }

    &-CartItemDescription {
        margin-block-start: 5px;

        p {
            font-size: 13px;
            line-height: 1.5;

            @include mobile {
                font-size: 15px;
            }
        }
    }

    &-CartItem {
        display: grid;
        grid-template-columns: 96px auto auto;
        grid-column-gap: 6px;
        margin: 0 0 6px;
        padding: 0;

        @include mobile {
            grid-template-columns: 112px auto auto;
            grid-column-gap: 7px;
            margin: 0 0 7px;
        }

        &::before {
            content: none;
        }

        > * {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &-Thumbnail {
        width: 96px;
        height: 96px;

        @include mobile {
            width: 112px;
            height: 112px;
        }
    }

    &-Title {
        p {
            margin: 0;
            padding: 0;
            text-align: start;
        }
    }

    &-Details {
        justify-self: end;
    }

    &-ExpandableContent {
        border-block-start: none;

        &Button {
            padding-inline: 0;

            .Checkout & {
                cursor: pointer;
                padding-top: 16px;
                padding-bottom: 16px;
            }
        }

        &:last-of-type {
            .Checkout & {
                border-bottom: 0 none;
                border-top: 1px solid $grey;
            }
        }

        &Content {
            .Checkout & {
                max-height: 0;
                overflow: hidden;
                opacity: 0;
                margin-right: -8px;

                @include wide-desktop {
                    margin-right: -24px;
                }

                &_isContentExpanded {
                    opacity: 1;
                    max-height: 100%;
                }
            }
        }
    }

    &-ExpandableContentHeading {
        font-size: 18px;

        .Checkout & {
            cursor: pointer;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.2px;
        }
    }

    .ExpandableContent-ToggleButton {
        height: 20px;
        width: 20px;
        inset-inline-start: 3px;
    }

    &-Coupon {
        @include mobile {
            padding: 10px 14px;
            border-block-end: $divider-border;
            margin: 0 -14px;
            width: auto;
        }
    }

    &-CmsBlock {
        padding: 10px 0;
        border-block-end: $divider-border;
    }

    .ProductPrice {
        margin-block-start: 0;
        font-weight: bold;
        line-height: 1;
        font-size: 18px;

        .Checkout & {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
        }

        &-SubPrice {
            display: block;
            font-size: 12px;
        }
    }

    .CartItem-Wrapper {
        padding: 12px 0;

        @include mobile {
            padding: 14px 0;
        }

        .Checkout & {
            padding: 24px 0;
        }
    }

    .CartItem {
        &:first-child {
            .CartItem-Wrapper {
                .Checkout & {
                    padding-top: 8px;
                }
            }
        }
    }

    &-Text {
        text-align: end;
        margin-block-end: 0;

        span {
            display: block;
            font-size: 12px;
            font-weight: 400;
        }
    }

    &-AppendedContent {
        flex-basis: 100%;
        font-size: 12px;
    }

    &-ButtonWrapper {
        &_isEmpty {
            display: none;
        }
    }
}
