/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkout-payment-check-mark-color: var(--primary-base-color);
    --checkout-payment-check-mark-hover-color: var(--secondary-dark-color);
    --checkout-payment-border-color: var(--primary-divider-color);
}

.CheckoutPayment {
    margin-block-start: 16px;
    margin-block-end: 0;

    &::before {
        content: none;
    }

    &:first-child {
        margin-block-start: 28px;
    }

    &:last-child {
        margin-block-end: 0;
    }

    &-Button {
        --check-mark-opacity: 0;
        border: 1px solid $grey;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 1;
        letter-spacing: -0.0008em;
        cursor: pointer;
        padding: 12px 15px;
        min-height: 48px;
        text-align: left;

        @include desktop {
            max-width: 334px;
        }

        &.boxnow {
            margin-bottom: 16px;
        }

        &_isSelected {
            border-color: $black;

            &.boxnow {
                [type=checkbox] + .input-control {
                    border-width: 4px;
                    border-color: var(--checkbox-border-color-active);
                }
            }
        }
    }

    .Field_type_checkbox {
        margin-block-start: 0;
        height: 21px;
        flex-shrink: 0;

        @include desktop {
            height: 14px;
        }

        .Checkout & {
            margin-top: 0;
        }

        .Field-CheckboxLabel {
            vertical-align: top;
        }
    }

    .klarna_pay_later {
        flex-shrink: 0;

        img {
            padding-left: 5px;
            flex-shrink: 0;
            vertical-align: top;
        }
    }

    .boxnow {
        margin-left: auto;
        text-align: left;
        display: block;
        margin-bottom: -3px;
    }
}