/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CheckoutBilling {
    background: $white;
    box-shadow: 0px 4px 4px $greyF0F0F0;
    padding: 24px 20px 34px;
    margin-block-start: 16px;

    @include wide-desktop {
        padding: 32px 52px;
    }

    &:first-child {
        margin-top: 0;
    }

    &-Button {
        width: 294px;
    }

    &-Checkbox {
        display: inline-block;
        font-size: 14px;
        margin-block-start: 24px;
        margin-inline-end: 34px;
    }

    &-TermsAndConditions {
        font-size: 14px;
        margin: 0;
        margin-block-start: 24px;

        @include mobile {
            font-size: 15px;
            margin-block-end: 28px;
        }

        &Container {
            display: flex;
            align-items: center;
        }

        &-Checkbox {
            &.Field {
                .Checkout & {
                    margin-block: 0;

                    .Field-CheckboxLabel {
                        vertical-align: top;
                    }
                }
            }
        }

        .Warning {
            color: red;
            margin-top: 5px;
            margin-left: 25px;

            @include mobile {
                margin-left: 32px;
            }
        }
    }

    &-TACLabel {
        display: inline;
    }

    &-TACLink {
        font-size: 14px;
        font-weight: 700;
        color: var(--link-color);
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &:focus {
            text-decoration: underline;
        }
    }

    .Checkout{
        &-OrderTotal{
            dd {
                text-align: end;

                span {
                    display: block;
                    font-size: .6em;
                    font-weight: 300;
                }
            }
        }
    }

    .Checkout-Heading + .sameAsShippingAddress {
        margin-top: 28px;
    }
}
