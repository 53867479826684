/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkout-payment-check-mark-color: var(--primary-base-color);
    --checkout-payment-check-mark-hover-color: var(--secondary-dark-color);
    --checkout-payment-border-color: var(--primary-divider-color);
}

.CheckoutGroupPayment {
    padding-block: 19px;
    padding-inline-start: 0;
    margin-block-end: 0;

    @include tablet {
        width: 100%;
    }

    &::before {
        content: none;
    }

    &:first-child {
        border-block-start: 0;
    }

    &:last-child {
        margin-block-end: 0;
    }

    &-Button {
        --check-mark-opacity: 0;
        width: 100%;
        display: flex;
        align-items: flex-start;
        font-size: 14px;
        cursor: pointer;
    }

    .Field_type_checkbox {
        margin-block-start: 0;
    }

    .CheckoutPayment-Button {
        .Field {
            .Checkout & {
                margin-top: 0;
                height: 14px;

                @include desktop {
                    width: auto;
                }

                label {
                    vertical-align: top;
                }
            }
        }
    }

    .Field {
        .Checkout & {
            margin-top: 0;

            @include desktop {
                max-width: 334px;
                width: 334px;
                flex-shrink: 0;
            }
        }

        + p {
            border-top: 1px solid $grey;
            padding-top: 16px;

            @include desktop {
                border-top: 0 none;
                border-left: 1px solid $grey;
                padding-top: 0;
                margin-top: 0;
                padding-left: 32px;
            }

            @include ultra-narrow-desktop {
                border-top: 1px solid $grey;
                border-left: 0 none;
                padding-top: 16px;
                padding-left: 0;
                margin-top: 20px;
            }

            img {
                width: auto;
                max-width: 100%;
                min-height: 30px;
                margin-top: 20px;
                display: block;
            }
        }
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin: 20px 0 0;
    }

    &_ContentWrapper {
        margin-top: 16px;
        width: 100%;

        @include desktop {
            display: flex;
            gap: 32px;
        }

        @include ultra-narrow-desktop {
            display: block;
        }


        &_seperator {
            width: 100%;
        }
    }
}
