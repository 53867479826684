/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CheckoutAddressBook {
    width: 100%;

    &-Wrapper {
        @include wide-desktop {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 40px;
        }
    }

    &-Button {
        margin-top: 34px;
    }

    & &-Button {
        @include mobile {
            width: 100%;
        }

        &_isCustomAddressExpanded {
            display: none;
        }
    }

    .FieldForm-Fields {
        grid-column-gap: 14px;
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        max-width: 686px;

        @include desktop {
            grid-column-gap: 17px;
        }

        .Field_type_checkbox {
            grid-column: 1 / -1;
        }

        .Field {
            width: 100%;

            @include desktop {
                width: calc(50% - 9px);
            }

            &.Field_type_checkbox {
                width: 100%;
            }

            &.telephone {
                width: calc(67% - 7px);

                @include desktop {
                    width: calc(31% - 6px);
                }

                @include ultra-narrow-desktop {
                    width: calc(50% - 9px);
                }
            }

            &.type_document {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                @include desktop {
                    grid-column-gap: 17px
                }
            }

            &.postcode {
                width: calc(33% - 7px);

                @include desktop {
                    width: calc(17% - 6px);
                }

                @include ultra-narrow-desktop {
                    width: calc(50% - 9px);
                }

                input {
                    @include mobile {
                        padding-right: 12px;
                    }

                    @media (max-width: 350px) {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }
            }
        }
    }

    .FieldSelect-Options {
        &_isExpanded {
            max-height: 302px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 16px;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 16px;
        }
    }
}
