/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountOverlay {
    &-Legend {
        legend {
            padding: 8px 0;
        }
    }

    &-SignUpButton {
        margin-block-start: 24px;

        @include mobile {
            margin-block-end: 18px;
        }
    }

    &-SignInButton {
        height: fit-content;
    }

    &-PasswordBlock {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 16px;
        }
    }

}
