/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Checkout {
  .CheckoutShipping {
    background: $white;
    box-shadow: 0px 4px 4px $greyF0F0F0;
    padding: 24px 20px 34px;

    @include wide-desktop {
      padding: 32px 52px;
    }

    &-Button {
      @include desktop {
        width: 294px;
      }

      @include mobile {
        width: 100%;
      }
    }

    .Checkout-StickyButtonWrapper {
      display: none !important;
    }

    textarea {
      max-width: 686px;
      width: 100%;
    }
  }

  &-TotalValue {
    text-align: end;
  }

  &-SubPrice {
    white-space: nowrap;
    display: block;
    font-weight: 400;
    font-size: 12px;
  }
}
