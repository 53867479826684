/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkout-address-table-border: transparent;
    --checkout-address-table-hover-border: var(--secondary-base-color);
    --checkout-address-table-check-mark-hover-color: var(--secondary-dark-color);
    --checkout-address-selected-color: var(--primary-base-color);
    --checkout-address-table-check-mark-selected-color: var(--secondary-base-color);
}

.CheckoutAddressTable {
    width: 100%;

    &-Button {
        --check-mark-opacity: 0;

        border: 2px solid var(--checkout-address-table-border);
        border-radius: 2px;
        transition-property: border-color, background, border-color;
        transition-duration: 150ms;
        transition-timing-function: ease-in;
        width: 100%;
        margin-block-start: 34px;

        .KeyValueTable-Heading {
            .RadioButtonIcon {
                float: inline-end;
                display: inline-block;
                inset-block-start: 3px;
            }
        }

        &:not(&_isSelected):hover {
            --check-mark-opacity: 1;
            --checkout-address-table-border: var(--checkout-address-table-hover-border);
            --check-mark-color: var(--checkout-address-table-check-mark-hover-color);
        }

        &_isSelected {
            --check-mark-opacity: 1;
            --checkout-address-table-border: var(--checkout-address-selected-color);
            --check-mark-color: var(--checkout-address-table-check-mark-selected-color);
        }
    }

    &-SelectedLabel {
        color: var(--checkout-address-selected-color);

        &::before {
            content: ' - ';
            color: $black;
        }
    }

    .KeyValueTable {
        margin: 0;
    }

    td,
    th {
        width: auto;
    }
}
