/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutSuccess {
    &-ContinueButton {
        &.Button {
            width: 100%;
            margin-top: 15px;

            @include desktop {
                margin-block-start: 29px;
            }
        }
    }

    .Button {
        @include mobile {
            width: 100%;
            text-align: center;
        }
    }

    .CheckoutRegistrationLink {
        margin-block-start: 20px;
    }

    &_image {
        text-align: center;
        margin-bottom: 37px;

        @include desktop {
            margin-bottom: 25px;
        }

        img {
            width: auto;
            vertical-align: top;
        }
    }

    p {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0;
    }

    &_text {
        text-align: center;
    }

    &_Columns {
        @include desktop {
            display: flex;
            justify-content: center;
            max-width: 982px;
            margin: 25px auto 0;
            gap: 14.5%;
        }
    }

    &_Column {
        @include desktop {
            flex-shrink: 0;
            width: 42.75%;
        }

        &:first-child {
            @include desktop {
                padding: 0 8px;

                .CheckoutSuccess-ButtonWrapper {
                    margin: 0 -8px;
                }
            }
        }

        &:not(:first-child) {
            @include mobile {
                border-top: 1px solid $grey;
                margin-top: 29px;
                padding-top: 19px;
            }
        }
    }

    &_Subtitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        margin-bottom: 13px;

        .CheckoutSuccess_Column:nth-child(2) & {
            margin-bottom: 0;
        }
    }

    .MyAccountOverlay {
        &-Legend {
            legend {
                display: none;
            }
        }

        &-Checkbox {
            margin-top: 13px;
            margin-bottom: 0;
        }

        &-SignUpButton {
            &.Button {
                width: 100%;
                margin-bottom: 0;
            }
        }

        &-PasswordBlock {
            display: block;

            .Field {
                margin-top: 20px;

                &_type_password_hint {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.0008em;
                    margin-top: 4px;
                }
            }

            input {
                width: 100%;
            }
        }
    }
}
