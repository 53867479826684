/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkout-delivery-option-check-mark-color: var(--primary-base-color);
    --checkout-delivery-option-check-mark-hover-color: var(--secondary-dark-color);
    --checkout-delivery-option-border-color: var(--primary-divider-color);
}

.CheckoutDeliveryOption {
    margin-block-start: 16px;
    margin-block-end: 0;

    &_isDisabled {
        cursor: not-allowed;
    }

    &:first-child {
        margin-block-start: 28px;
    }

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0;
    }

    &-Button {
        --check-mark-opacity: 0;
        border: 1px solid $grey;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        cursor: pointer;
        padding: 12px 15px;
        text-align: left;
        display: grid;
        grid-template-columns: auto 1fr;

        @include desktop {
            max-width: 334px;
        }

        &_isDisabled {
            pointer-events: none;
        }

        &_isSelected {
            border-color: $black;
        }

        .boxnowframe {
            grid-column: 1/-1;
            padding: 22px 0 12px;
            margin: 12px -16px -15px;
            background: #fff;

            &:before {
                content: "";
                width: 100%;
                position: absolute;
                top: 0;
                border-bottom: 1px solid $black;
            }

            iframe {
                max-width: var(--checkout-delivery-options-width);
                width: var(--checkout-delivery-options-width);
            }
        }
    }

    &-Row {
        strong {
            display: inline-block;
            white-space: pre;
        }

        &.Boxnow {
            padding-right: 61px;
            position: relative;
            min-height: 37px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            img {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: auto;
                max-width: 50px;
            }
        }
    }

    &-method,
    &-title {
        margin-right: 5px;
    }

    &-SubPrice {
        font-size: 12px;
        font-weight: 400;
        text-align: end;
        position: absolute;
        inset-inline-start: 0;
        inset-block-start: 1.5em;
        white-space: nowrap;
    }

    &-Message {
        color: var(--primary-error-color);
    }

    .Field_type_checkbox {
        &.Field {
            .Checkout & {
                margin-block-start: 0;
                height: 21px;

                @include desktop {
                    height: 14px;
                }

                .Field-CheckboxLabel {
                    vertical-align: top;
                }
            }
        }
    }
}
